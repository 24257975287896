// TODO extract links to consts
export const cardsData = [
  {
    headerText: 'Mavryk Network',
    iconId: 'mav-logo-small',
    link: 'https://mavryk.org/',
    description: (
      <>
          A bespoke Layer 1 blockchain designed to bridge real-world assets with the dynamic realm of DeFi and foster a thriving & secure digital economy.
      </>
    ),
  },
  {
    headerText: 'Maven Finance',
    iconId: 'rocket-small',
    link: 'https://atlasnet.mavenfinance.io/',
    description: (
      <>
          A DeFi bank for people, Maven is a DAO operated financial ecosystem that lets users borrow and earn on their terms, while participating in the governance of the platform.
      </>
    ),
  },
  {
    headerText: 'Equiteez',
    iconId: 'eq',
    link: 'https://equiteez.com/',
    description: (
      <>
          A digital platform that allows investors to purchase fractional shares of fully compliant, income-generating assets worldwide, starting from as little as $50.
      </>
    ),
  },
  {
    headerText: 'Mavryk Wallet',
    iconId: 'mw',
    link: 'https://chromewebstore.google.com/detail/mavryk-wallet/cgddkajmbckbjbnondgfcbcojjjdnmji',
    description: (
      <>
          A secure, user-friendly wallet that connects you to the Mavryk ecosystem and simplifies your experience with crypto, RWAs, DeFi, and collectibles.
      </>
    ),
  },
    {
        headerText: 'Nexus',
        iconId: 'nex',
        link: 'https://nexus.mavryk.org',
        description: (
            <>
                Explore blocks, participate in network governance, manage treasury functions, and navigate domain services through a single, intuitive platform, putting the full potential of decentralized technology at your fingertips.
            </>
        ),
    },
];
